.form-control, .input-group-text {
    color: #000000 !important;
}

.react-confirm-alert-body h1 {
    color: #666666 !important
}

.react-bootstrap-table table {
    table-layout: auto;
}

.action-btn {
    margin: 2.5px !important;
    padding: 10px !important;
}

.black-text, .p, .h1, .h2, .h3, .h4, .h5, ol li, ul li{
    color: #000000 !important;
}

.clickable-content {
    cursor: pointer;
}