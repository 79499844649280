html, body {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
}

.reward-container {
    /* background-image: url(../img/confetti-transparent.png); */
    background-color: rgb(228, 228, 228) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
    height: 100%;
    overflow:auto; 
}

.main-title{
    font-size: 60px;
    font-family: courier;
    font-weight: bold;
}

.main-description{
    font-size: 30px;
    font-family: courier;
    font-weight: bold;
}